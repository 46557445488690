  import React, { useEffect,useState } from 'react';
  import { connect } from 'react-redux';
  import { useTranslation } from 'react-i18next';
  import { Layout, Card, Spin } from 'antd';
  import { useParams, useNavigate } from 'react-router-dom';
  import AuthActions from './LoginPage/reducer';
  import NewPasswordForm from '../Components/Forms/NewPasswordForm';
  import LoginHeader from '../Components/LoginHeader';
  import Images from '../Images';

  function NewPasswordPage({ error, fetching, isMobile, message, ...props }) {
    const { t } = useTranslation();
    const { code } = useParams();
    const navigate = useNavigate();
  
    const [confirm_code, setCodeFromUrl] = useState(null);

    const getCodeFromUrl = ({ pathname, search }) => {
      const searchString = search || (pathname.includes('?') ? pathname.split('?')[1] : '');
      const p = new URLSearchParams(searchString);
      return p.get('code');
    };
  
    function handleSubmit(password) {
      if (fetching) return;
      props.createUserPassword(password, false, confirm_code); 
    }
  
    useEffect(() => {
      document.title = 'Create New Password - Dropship';
    }, []);
  
    useEffect(() => {
      const codeFromUrl = getCodeFromUrl({
        pathname: window.location.pathname,
        search: window.location.search,
      });
      
      if (codeFromUrl) {
        setCodeFromUrl(codeFromUrl); 
      }else{
        if(window.location.pathname.includes('setting')){
          navigate('/setting/account')
        }else{
          navigate('/login')
        }
      }
    }, [code]);

    return (
      <Layout>
        <Spin size="large" spinning={fetching}>
          <div className="login-row">
            <LoginHeader />

            <div className="login-content">
              <div className="content-main">
                <div className="content-left content-left_32">
                  <Card className="login-card">
                    <h2 className="card-title">{t('Create New Password')}</h2>
                    <div className="text-content">
                      {t(
                        'Your new password must be different from previously used passwords',
                      )}
                    </div>
                    <NewPasswordForm onSubmit={handleSubmit} error={error}  resetError={props.resetError} message={message} resetMessage={props.resetMessage}/>
                  </Card>
                </div>

                <div className="content-right large">
                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${
                        isMobile
                          ? Images.createPasswordMobile
                          : Images.createPassword
                      })`,
                      maxWidth: 565,
                      margin: '0 auto',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Spin>
      </Layout>
    );
  }

  const mapStateToProps = (state) => ({
    error: state.auth.error,
    fetching: state.auth.fetching,
    isMobile: state.nav.isMobile,
    message: state.auth.message,
  });

  // wraps dispatch to create nicer functions to call within our component
  const mapDispatchToProps = (dispatch) => ({
    resetError: () => dispatch(AuthActions.authErrorReset()),
    resetMessage: () => dispatch(AuthActions.authMessageReset()),
    createUserPassword: (password, check, confirm_code) =>
      dispatch(AuthActions.signUpRequest(password, check, confirm_code)),
  });

  export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);
