/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Input, Row, Col, Typography } from 'antd';

import PasswordValidator from '../PasswordValidator';
import Icon from '../../Icon';
import './styles-change-password.less';
import cls from 'classname';
import ButtonComponent from "../Button";

function ChangePasswordForm({
  userError,
  saveResult,
  userSaveResultReset,
  onClose,
  onSubmit,
  userErrorsReset,
  clearForm,
  onChangeModal,
  isMobile,
  setField,
  handleSendCodePassword,
  email
}) {
  const { t } = useTranslation();
  const currentText = t('Confirm your current password');
  const [errorText, setErrorText] = useState(null);
  const [currentPassIconColor, setCurrentPassIconColor] = useState('#707BA0');
  const [newPassIconColor, setNewPassIconColor] = useState('#707BA0');
  const [repeatPassIconColor, setRepeatPassIconColor] = useState('#707BA0');
  const [currentError, setCurrentError] = useState({
    text: currentText,
    error: false,
  });

  const [values, setValues] = useState({
    current: null,
    password: null,
    confirm: null,
  });

  const [form] = Form.useForm();

  useEffect(() => {
    if (userError) {
      if (typeof userError === 'string') {
        if (userError.length > 100) {
          setErrorText('Server response error');
        } else {
          setErrorText(userError);
        }
      } else {
        if (userError?.detail === 'Current password is entered incorrectly') {
          setCurrentError({ text: userError.detail, error: true })
          setCurrentPassIconColor('#D71313')
        } else {
          setErrorText(Object.values(userError)[0]);
          setNewPassIconColor('#D71313')
          setRepeatPassIconColor('#D71313')
        }
      }
    }
  }, [userError]);

  useEffect(() => {
    clearFormData();
    if (saveResult?.create) {
      onClose();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [clearForm, saveResult]);

  const onFinish = (value) => {
    const data = { ...value };
    userErrorsReset();
    onSubmit(data.current, data.password);
  };
  const handleClose = () => {
    clearFormData();
    onClose();
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) {
      setErrorText(errorFields[0].errors);
      setNewPassIconColor('#D71313');
      setRepeatPassIconColor('#D71313');
    }
  };
  function clearFormData() {
    form.resetFields();
    setValues({ current: null, password: null, confirm: null });
    setErrorText(null);
    setCurrentError({ text: currentText, error: false });
    userErrorsReset();
    userSaveResultReset();
  }

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
    setNewPassIconColor('#707BA0');
    setRepeatPassIconColor('#707BA0');
  }

  function onFocusHandler(e) {
    handleClearError(e, e.target.name);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === 'current') {
      userErrorsReset();
      setCurrentError({ text: currentText, error: false });
    }
    setValues({ ...values, [name]: value });
  }
  function forgotPassword() {
    userErrorsReset();
    setField('')
    onChangeModal('forgotPassword');
    handleSendCodePassword(email)
  }

  return (
    <Form
      className="change-form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      validateTrigger="onSubmit"
      layout="vertical"
      form={form}
    >
      <Typography.Link
        onClick={forgotPassword}
        className="typografy-link-forgotpassword"
      >
        {t('Forgot Password?')}
      </Typography.Link>
      <Form.Item
        label={t('Current Password')}
        className="change-form-item current-password"
        validateStatus={currentError.error ? 'error' : null}
      >
        <div
          className={cls('confirm-message-text', {
            error_text: currentError.error,
          })}
        >
          {currentError.error && <Icon type={'alert_triangle_error'} role={'icon'} />}
          {currentError.text}
        </div>
        <Form.Item name="current" noStyle>
          <Input.Password
            size="large"
            name="current"
            placeholder={t('Password')}
            autoComplete="current-password"
            visibilityToggle
            iconRender={(visible) =>
              visible ? (
                <Icon type="eye" color="#C5CCE3" />
              ) : (
                <Icon type="eye_invisible" color="#C5CCE3" />
              )
            }
            prefix={<Icon type={'lock'}
                          color={currentError?.text !== 'Confirm your current password' ? '#D71313' : currentPassIconColor}
            />}
            onChange={(e) => {
              handleChange(e)
              setCurrentPassIconColor('#225AEA')
            }}
            onFocus={(e) => {
              onFocusHandler(e)
              setCurrentPassIconColor('#225AEA')
            }}
            onBlur={() => setCurrentPassIconColor('#707BA0')}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={t('New Password')}
        className="change-form-item new-p"
        rules={[{ required: true, message: 'Required field' }]}
        validateStatus={errorText ? 'error' : null}
      >
        <PasswordValidator value={values.password} error={errorText} />
        <Form.Item name="password" noStyle>
          <Input.Password
            size="large"
            name="password"
            placeholder={t('New Password')}
            autoComplete="new-password"
            visibilityToggle
            iconRender={(visible) =>
              visible ? (
                <Icon type="eye" color="#C5CCE3" />
              ) : (
                <Icon type="eye_invisible" color="#C5CCE3" />
              )
            }
            prefix={<Icon type={'lock'} color={newPassIconColor} />}
            onChange={(e) => {
              handleChange(e)
              setNewPassIconColor('#225AEA')
            }}
            onFocus={(e) => {
              onFocusHandler(e)
              setNewPassIconColor('#225AEA')
            }}
            onBlur={() => setNewPassIconColor('#707BA0')}
          />
        </Form.Item>
      </Form.Item>

      <Form.Item
        label={t('Repeat New Password')}
        name="confirm"
        className="change-form-item last-change"
        validateStatus={errorText ? 'error' : null}
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: t('Please confirm your password!'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (
                !value ||
                !getFieldValue('password') ||
                getFieldValue('password') === value
              ) {
                return value?.length < 6
                  ? Promise.reject(t('Must be at least 6 characters'))
                  : Promise.resolve();
              }
              return Promise.reject(t('Passwords do not match'));
            },
          }),
        ]}
      >
        <Input.Password
          size="large"
          name="confirm"
          placeholder={t('Confirm Password')}
          autoComplete="dontshow"
          visibilityToggle
          iconRender={(visible) =>
            visible ? (
              <Icon type="eye" color="#C5CCE3" />
            ) : (
              <Icon type="eye_invisible" color="#C5CCE3" />
            )
          }
          prefix={<Icon type={'lock'} color={repeatPassIconColor} />}
          onChange={(e) => {
            handleChange(e)
            setRepeatPassIconColor('#225AEA')
          }}
          onFocus={(e) => {
            onFocusHandler(e)
            setRepeatPassIconColor('#225AEA')
          }}
          onBlur={() => setRepeatPassIconColor('#707BA0')}
        />
      </Form.Item>
      {isMobile ? (
        <div className="double-btn-wrp">
          <ButtonComponent
            type="primary"
            htmlType="button"
            className="btn-primary btn-cancel"
            onClick={handleClose}
          >
            {t('Cancel')}
          </ButtonComponent>
          <ButtonComponent
            type="primary"
            htmlType="submit"
            className="btn-primary btn-submit"
            disabled={!values.confirm || !values.current}
          >
            {t('Continue')}
          </ButtonComponent>
        </div>
      ) : (
        <Row className="change-btn-block" gutter={[16, 0]} justify="end">
          <Col flex="95px">
            <ButtonComponent
              type="primary"
              htmlType="button"
              className="btn-primary btn-cancel border-only"
              style={{ width: '100%' }}
              onClick={handleClose}
            >
              {t('Cancel')}
            </ButtonComponent>
          </Col>
          <Col flex="109px">
            <ButtonComponent
              type="primary"
              htmlType="submit"
              className="btn-primary"
              style={{ width: '100%', margin: 0 }}
              disabled={!values.confirm || !values.current}
            >
              {t('Continue')}
            </ButtonComponent>
          </Col>
        </Row>
      )}
    </Form>
  );
}

export default ChangePasswordForm;
