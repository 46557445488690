/* User services */

import {API, encryptedData} from '../../Services/Api';

const request = new API();

export const getUserInfo = () => request.api.get(`/user/account/`);

export const updateUserInfo = (info) => {
  if (info.avatar) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    const form = new FormData();
    form.append('avatar', info.avatar.media);
    return request.api.patch(`/user/update/`, form, { headers });
  }
  return request.api.put(`/user/update/`, encryptedData({ ...info }));
};

export const changePassword = ({ current, password }) =>
  request.api.put(`/user/user/change_password/`, encryptedData({old_password: current, new_password: password}) )

export const sendCodePassword = (email) => {
  return request.api.post(`auth/forgot-password/`, encryptedData({ email }));
};

export const confirmCodePassword = (code) => {
  return request.api.get(`auth/forgot-password/`, { code, source: 'settings' });
};

export const createNewPassword = ({ password,confirm_code }) => {
  return request.api.post(`/user/user/set_password/`, encryptedData({
    new_password: password,
    confirm_code: confirm_code
  }));
};

export const changeEmails = ({ password, new_email }) =>
  request.api.post(`/user/change-email/`, encryptedData({'new_email': new_email, 'password': password}));

export const verifyEmail = ({ password, new_email }) =>
  request.api.post(`/user/confirm-email/`, encryptedData({'new_email': new_email, 'password': password}));

export const confirmEmails = ({hash, isVerify}) => {
  return request.api.get(`/user/${isVerify ? 'confirm' : 'change'}-email/?code=${hash}`);
};

export const changeLanguage = (lang) =>
  request.api.post(`/user/set-language/`, encryptedData({ language: lang }));
